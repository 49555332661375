import React, { FC, useEffect, useMemo, useState } from 'react';
import { PricingCardToggle } from '@lesmills-international/components';
import { Product } from '@src/type/Product';
import { Coupon } from '@src/type/Coupon';
import PlanCard from '../../signup/selectPlan/PlanCardTiering';
import { Wrapper, SwitchWrapper, PlansContainer } from './style';
import { Subscription } from '../../../../../gatsby-theme-engagement/src/backend';

interface Props {
  displayOnlyCurrentPlan?: boolean;
  changeSubscriptionPagePrismicData: {
    current_plan_text: string;
  };

  planCardPrismicData: {
    monthly_text: string;
    annually_text: string;
    best_value: string;
    best_value_position: string;
    three_monthly_text: string;
  };
  countryCode: string;
  availablePlans: Array<Product> | null;
  currentProductHandle: string;
  nextProductHandle: string | null;
  currentProduct: Product | null;
  currentSubscription: Subscription | null;
  coupon: Coupon | null;
  getSelectedPlan: (newSelectedPlan: Product) => void;
  selectedPlan: Product;
  showThreeMonthly?: boolean;
  customBadgeText?: string;
}

const AvailableTieringSubscriptions: FC<Props> = ({
  changeSubscriptionPagePrismicData,
  planCardPrismicData,
  countryCode,
  availablePlans,
  currentProductHandle,
  nextProductHandle,
  currentProduct,
  currentSubscription,
  coupon,
  getSelectedPlan,
  selectedPlan,
  showThreeMonthly,
  displayOnlyCurrentPlan,
  customBadgeText,
}) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const { current_plan_text } = changeSubscriptionPagePrismicData;
  const { monthly_text, annually_text, best_value, best_value_position, three_monthly_text } =
    planCardPrismicData;

  const toggleOptions = [monthly_text, annually_text];

  let frequencyMapping = {
    [monthly_text]: 1,
    [annually_text]: 12,
  };

  if (showThreeMonthly) {
    frequencyMapping = { ...frequencyMapping, [three_monthly_text]: 3 };
    toggleOptions.splice(1, 0, three_monthly_text);
  }

  const [paymentFrequency, setPaymentFrequency] = useState(monthly_text);

  const currentPlan = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    // LPE-3704: If no current subscription, use current product (new subscription)
    if (!currentSubscription) {
      return currentProduct;
    }

    const plan = currentProduct;

    plan.regularPrice = plan?.product_price_point?.price_in_cents;
    plan.price = currentSubscription?.preview_renewal?.totalInCentsTaxExclusive;

    // LPE-3704: If next_product_handle !== currentHandle, use current price
    if (nextProductHandle) {
      if (nextProductHandle !== currentProductHandle) {
        plan.price = plan?.product_price_point?.price_in_cents;
      }
    }

    return plan;
  }, [currentProduct, currentProductHandle, currentSubscription, nextProductHandle]);

  const isDiscounted = !!coupon && !!currentPlan && currentPlan?.price < currentPlan?.regularPrice;

  const handleSelectPlan = (newSelectedPlanId: string) => {
    availablePlans?.forEach((plan) => {
      if (newSelectedPlanId === plan.product_handle) {
        getSelectedPlan(plan);
      }
    });
  };

  const getCurrentPlanFrequency = useMemo(() => {
    const interval = currentPlan?.interval;
    return Object.keys(frequencyMapping).find((key) => frequencyMapping[key] === interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan?.interval]);

  useEffect(() => {
    if (displayOnlyCurrentPlan && getCurrentPlanFrequency) {
      setPaymentFrequency(getCurrentPlanFrequency);
    }
  }, [displayOnlyCurrentPlan, getCurrentPlanFrequency]);

  const plans = displayOnlyCurrentPlan ? [currentPlan] : availablePlans;

  if (!plans || plans.length < 1) {
    return null;
  }

  if (!PricingCardToggle) return null;

  return (
    <Wrapper>
      {!displayOnlyCurrentPlan && (
        <SwitchWrapper>
          <PricingCardToggle
            options={toggleOptions}
            getSelectedText={setPaymentFrequency}
            defaultText={paymentFrequency}
            badge={{
              label: best_value,
              positionText: best_value_position || annually_text,
            }}
          />
        </SwitchWrapper>
      )}
      <PlansContainer>
        {countryCode &&
          plans.map((plan) =>
            plan ? (
              <PlanCard
                plan={plan}
                key={plan?.name}
                handleSelectPlan={handleSelectPlan}
                selectedPlan={selectedPlan}
                isHidden={false}
                pricingData={planCardPrismicData}
                countryCode={countryCode}
                noTrialDisplay
                currentFrequency={frequencyMapping[paymentFrequency]}
                collapse
                currentProductHandle={currentProductHandle}
                currentPlanText={customBadgeText || current_plan_text}
                forceStrikeThroughDescription={isDiscounted}
              />
            ) : null
          )}
      </PlansContainer>
    </Wrapper>
  );
};

export default AvailableTieringSubscriptions;
