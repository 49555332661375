import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { breakpoint } from '../themes/breakpoints';
import COLORS from '../themes/colors';

const Wrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    color: ${COLORS.darkGrey};
    letter-spacing: 0em;
`;

const Background = styled.div`
    background-color: #e5e5e5;
    min-height: 100vh;
`;

const ContentWrapper = styled.div`
    position: relative;
    background-color: ${COLORS.white};
    z-index: 2;
    ${breakpoint('md')`
        background-color: #e5e5e5;
        background: none;
    `}
`;

const DescriptionWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    background-color: ${COLORS.white};
    padding: 2rem;
    ${breakpoint('md')`
        width: 100%;
    `}
    ${breakpoint('lg')`
        border-radius: 8px 8px 0px 0px;
        width: 800px;
    `}
`;

const DescriptionWrapperInner = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    ${breakpoint('md')`
        width: 500px;
    `}
`;

const Title = styled.h1`
    font-family: 'Inter SemiBold';
    letter-spacing: -0.02em;
    font-size: 46px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 2rem;
    ${breakpoint('md')`
        font-size: 70px;
        line-height: 84px;
    `}
`;

const Text = styled.p`
    font-family: Inter Medium;
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 1.5rem;
    ${breakpoint('md')`
        font-size: 24px;
        line-height: 36px;
    `}
`;

const CodeText = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    ${breakpoint('md')`
        font-size: 32px;
    `}
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
    font-family: Inter Medium;
    padding: 1rem 2rem;
    background-color: ${props => props.variant === 'secondary' ? COLORS.darkGrey : COLORS.green};
    color: ${COLORS.white};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: opacity 0.2s;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.9;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`;

const Input = styled.input`
    padding: 1rem;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    border: 1px solid ${COLORS.darkGrey};
    border-radius: 4px;
    font-family: Inter Regular;

    &::placeholder {
        color: ${COLORS.darkGrey};
    }
`;

interface AuthUrls {
    allow_url: string;
    deny_url: string;
}

const DeviceLogin: React.FC = () => {
    const location = useLocation();
    const [deviceCode, setDeviceCode] = useState<string>('');
    const [inputCode, setInputCode] = useState<string>('');
    const [isManualInput, setIsManualInput] = useState<boolean>(false);
    const [authUrls, setAuthUrls] = useState<AuthUrls | null>(null);
    const [error, setError] = useState<string>('');

    const fetchAuthUrls = async (code: string) => {
        try {
            const response = await fetch(`${process.env.GATSBY_DEVICE_AUTH_API_URL}?userCode=${code}`);
            if (!response.ok) {
                throw new Error('Failed to fetch authorization URLs');
            }
            const data: AuthUrls = await response.json();
            setAuthUrls(data);
        } catch (err) {
            setError('Failed to load authorization options. Please try again.');
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('userCode');
        if (!code) {
            setIsManualInput(true);
            return;
        }
        setDeviceCode(code);
        fetchAuthUrls(code);
    }, [location]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (inputCode) {
            setDeviceCode(inputCode);
            setIsManualInput(false);
            await fetchAuthUrls(inputCode);
        }
    };

    if (error) {
        return (
            <Background>
                <Wrapper>
                    <ContentWrapper>
                        <DescriptionWrapper>
                            <DescriptionWrapperInner>
                                <Title>Error</Title>
                                <Text>{error}</Text>
                                <Button onClick={() => navigate('/')}>
                                    Return Home
                                </Button>
                            </DescriptionWrapperInner>
                        </DescriptionWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Background>
        );
    }

    return (
        <Background>
            <Wrapper>
                <ContentWrapper>
                    <DescriptionWrapper>
                        <DescriptionWrapperInner>
                            <Title>Device Authorization</Title>

                            {isManualInput ? (
                                <>
                                    <Text>Please enter your device code:</Text>
                                    <Form onSubmit={handleSubmit}>
                                        <Input
                                            type="text"
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                            placeholder="Enter device code"
                                        />
                                        <Button
                                            type="submit"
                                            disabled={!inputCode}
                                        >
                                            Continue
                                        </Button>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <Text>
                                        A device is requesting access to your account using code:
                                    </Text>
                                    <CodeText>{deviceCode}</CodeText>
                                    <Text>Would you like to authorize this device?</Text>
                                    <ButtonContainer>
                                        {authUrls ? (
                                            <>
                                                <Button
                                                    as="a"
                                                    href={authUrls.allow_url}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    as="a"
                                                    href={authUrls.deny_url}
                                                    variant="secondary"
                                                >
                                                    Deny
                                                </Button>
                                            </>
                                        ) : (
                                            <Text>Loading authorization options...</Text>
                                        )}
                                    </ButtonContainer>
                                </>
                            )}
                        </DescriptionWrapperInner>
                    </DescriptionWrapper>
                </ContentWrapper>
            </Wrapper>
        </Background>
    );
};

export default DeviceLogin;
